@import url(https://themes.googleusercontent.com/fonts/css?kit=dpiI8CyVsrzWsJLBFKehGpLhv3qFjX7dUn1mYxfCXhI);

#privacy-policy-page ol {
    margin: 0;
    padding: 0
}

#privacy-policy-page table td,
#privacy-policy-page table th {
    padding: 0
}

#privacy-policy-page .c0 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Roboto";
    font-style: normal
}

#privacy-policy-page .c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Roboto";
    font-style: normal
}

#privacy-policy-page .c12 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

#privacy-policy-page .c1 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 30pt;
    font-family: "Roboto";
    font-style: normal
}

#privacy-policy-page .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Roboto";
    font-style: normal
}

#privacy-policy-page .c2 {
    padding-top: 6pt;
    padding-bottom: 6pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
}

#privacy-policy-page .c9 {
    padding-top: 6pt;
    padding-bottom: 6pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: center
}

#privacy-policy-page .c11 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
}

#privacy-policy-page .c10 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 4pt;
    font-style: normal
}

#privacy-policy-page .c8 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 50.4pt 72pt 50.4pt 72pt
}

#privacy-policy-page .c4 {
    font-weight: 400;
    font-family: "Roboto"
}

#privacy-policy-page .c6 {
    font-weight: 700;
    font-family: "Roboto"
}

#privacy-policy-page .c7 {
    height: 11pt
}

#privacy-policy-page .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#privacy-policy-page .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#privacy-policy-page li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

#privacy-policy-page p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

#privacy-policy-page h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#privacy-policy-page h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#privacy-policy-page h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#privacy-policy-page h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#privacy-policy-page h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#privacy-policy-page h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}