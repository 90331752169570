#react-select-4-listbox {
  text-transform: uppercase !important;
}

.form-floating>label {
  transition: .33s ease-in-out,transform .33s ease-in-out  !important;
  transform-origin: 0 -100px !important;
  width: -webkit-min-content !important;
  opacity: 1 !important;
}

.alertify-logs{
  z-index: 999999 !important;
}

input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

input[type=number] {
  -moz-appearance: textfield;
}
/*
.custom-focus-ring:focus { 
  outline: none !important;
  border-color: rgba(0, 0, 255, 0.75) !important;
  box-shadow: 0 0 30px rgba(0, 0, 255, 0.75) !important;
}
*/

s
.fade-in {
  transition: opacity 1.5s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1.5s ease;
}

footer {
  max-height: 30vh;
  background-image: url('../public/footer-background-image.png');
  background-size: contain;
}

tr:hover {
  cursor: pointer;
}

.pointer-on-hover:hover {
  cursor: pointer;
}

.dynamic-card-resize-patient-login {
  width: 600px;
}

.dynamic-card-resize {
  width: 600px;
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .dynamic-card-resize {
    width: 90%;
  }
}

/*
#staff-password-login {
  max-width: 200px;
}

#staff-email-login {
  max-width: 200px;
}
*/

.nav-bar-logo-custom {
  max-width: 200px;
  max-height: 75px;
}

.staff-page-container {
  background-color: #F0F0F0;
  min-height: 100vh;
}

body {
  background-color: lightgray;
  min-height: 100vh;
}

.lightgray-background {
  background-color: lightgray;
}

.range input[type=range] {
  -webkit-appearance: none;
  display: block;
  margin: 0;
  width: 100%;
  background: transparent;
}

.range input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  width: 100%;
  height: 40px;
  color: transparent;
  background: lightgray;
  border-radius: 999px;
  border: none;
}

.range input[type=range]::-moz-range-track {
  -webkit-appearance: none;
  width: 100%;
  height: 40px;
  color: transparent;
  background: lightgray;
  border-radius: 999px;
  border: none;
}

.range input[type=range]::-ms-track {
  -webkit-appearance: none;
  width: 100%;
  height: 40px;
  color: transparent;
  background: lightgray;
  border-radius: 999px;
  border: none;
}

.range input[type=range]::-ms-fill-lower {
  display: none;
}

.range input[type=range]::-ms-fill-upper {
  display: none;
}

.range input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
  border-radius: 30px;
  background: #3B71CB;
  box-shadow: 0px 2px 10px -2px black(1);
}

.range input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
  border-radius: 30px;
  background: #3B71CB;
  box-shadow: 0px 2px 10px -2px black(1);
}

.range input[type=range]::-ms-thumb {
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
  border-radius: 30px;
  background: #3B71CB;
  box-shadow: 0px 2px 10px -2px black(1);
}

.range .ticks {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: -1.5em;
}

.tick-multiple-lines span.one-line-text {
  position: relative;
  bottom: 2em;
}

.tick-multiple-lines span.two-line-text {
  position: relative;
  bottom: 3em;
}

.tick-multiple-lines span.three-line-text {
  position: relative;
  bottom: 4em;
}

.tick span.tick-percentage {
  position: relative;
  bottom: 50px;
}

.tick.text-center p.first-tick {
  position: relative;
  bottom: 110px;
}

.tick.text-center p.last-tick {
  position: relative;
  bottom: 90px;
}

.tick.text-center p.last-tick-2 {
  position: relative;
  bottom: 110px;
}

.range .tick {
  position: relative;
  display: flex;
  justify-content: center;
  width: 1px;
  background: gray;
  height: 10px;
  line-height: 50px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.range .tick-multiple-lines {
  position: relative;
  display: flex;
  justify-content: center;
  width: 1px;
  background: gray;
  height: 10px;
  line-height: 20px;
  padding-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.bigger-font-size {
  font-size: 20px;
  font-weight: bold;
}

.transparent-nav-item {
  opacity: 0;
}

#privacy-return-button {
  position: fixed;
}

.input-box-bigger {
  min-height: 50px !important;
  font-size: 22px !important;;
}

.form-check {
  display: flex !important;
  justify-content: center !important;
}

.radio-button-font-size {
  font-size: 20px !important;
  font-weight: bold !important;
}